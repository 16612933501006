import AppPage from 'src/components/AppPage/AppPage'
import Seo from 'src/components/seo/Seo'

function App() {
  return (
    <>
      <Seo title="Aplicativo | Decathlon" />
      <AppPage />
    </>
  )
}

export default App
